<template>
  <RegisterCheckout @onCheckout="onCheckout" />
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import RegisterCheckout from '@/components/credentials/register/RegisterCheckout'
import ChargebeeMixin from '@/mixins/ChargebeeMixin'
import LoadingMixin from '@/mixins/LoadingMixin'
import NotificationsMixin from '@/mixins/NotificationsMixin'
import { EVENTS } from '@/events/analytics'

export default {
  mixins: [ChargebeeMixin, LoadingMixin, NotificationsMixin],
  components: {
    RegisterCheckout,
  },
  computed: {
    ...mapGetters({
      selectedPlan: 'checkout/selectedPlan',
      subscriptionState: 'user/subscriptionState',
    }),
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateUserData',
      isMealPlannerLocked: 'globals/setIsMealPlannerLocked',
      mealPlanData: 'globals/setMealPlanData',
      refreshMealPlans: 'dashboard/refreshMealPlans',
      refreshDashboard: 'dashboard/refreshDashboard',
      linkWithSub: 'registration/linkUserWithSubscription',
    }),
    upgrade(data) {
      this.updateSubscription(data, {
        onSuccess: async (hostedPageId) => {
          this.setLoading(true)
          this.$posthog.sendEvent(EVENTS.subscription.renew, {
            'subscription plan id': this.selectedPlan.id,
          })
          await this.linkWithSub(hostedPageId)
          await this.updateUser(true)
          await Promise.all([
            this.isMealPlannerLocked(false),
            this.mealPlanData({ mealPlan: 'current' }),
            this.refreshMealPlans({}),
            this.refreshDashboard({}),
          ])
          this.setLoading(false)
          this.$router.replace('/plan')
        },
        onError: () => {
          this.setLoading(false)
          this.createInfoNotification({
            text: 'something went wrong',
            closeAfter: 5,
            color: 'red',
            full: true,
          })
        },
      })
    },
    onCheckout() {
      const chargeItems = []

      chargeItems.push({
        item_price_id: this.selectedPlan.chargebeeFrequency,
        quantity: 1,
      })

      this.selectedPlan.chargebeeAddons.forEach((addon) => {
        chargeItems.push({
          item_price_id: addon,
        })
      })

      this.selectedPlan.chargebeeCharges.forEach((charge) => {
        chargeItems.push({
          item_price_id: charge,
          charge_on_option: 'immediately',
        })
      })

      this.upgrade({
        plan_id: this.selectedPlan.id,
        subscription_items: chargeItems,
        coupon_ids: [...this.selectedPlan.chargebeeCoupon],
      })
    },
  },
}
</script>
