<template>
  <div id="register-goals-form">
    <div class="goals-container mt-4 mx-md-auto mx-2">
      <h2>What are your goals?</h2>
      <div class="goal-options mt-4 mt-md-5">
        <MPSelectBox
          v-for="(goal, i) in goals"
          class="goal-option"
          :key="i"
          :title="goal.title"
          :image="goal.icon"
          :isSelected="goal.selected"
          @click="goal.selected = !goal.selected"
          :data-spec="goal.dataSpec"
        />
      </div>
      <ButtonNext
        class="next-button"
        @click="next()"
        data-spec="next-button"
        :disabled="!!error"
      />
    </div>
  </div>
</template>

<script>
import ButtonNext from './ButtonNext'
import { mapGetters } from 'vuex'
import { events } from '@/utils/mixpanel.events'
import { EVENTS } from '@/events/analytics'

export default {
  name: 'RegisterGoalsForm',
  components: {
    ButtonNext,
  },
  data() {
    return {
      goals: [
        {
          title: 'Improve my health',
          icon: require('@/assets/icons/improve-health.svg'),
          selected: false,
          // keep typo until we're able to alias the property in Mixpanel
          intercomEvent: 'use_goal_improve_health',
          dataSpec: 'improve-my-health',
        },
        {
          title: 'Save time and money',
          icon: require('@/assets/icons/save-time.svg'),
          selected: false,
          intercomEvent: 'user_goal_save_time_and_money',
          dataSpec: 'save-time-money',
        },
        {
          title: 'Manage my weight',
          icon: require('@/assets/icons/manage-weight.svg'),
          selected: false,
          intercomEvent: 'user_goal_manage_weight',
          dataSpec: 'user-goal-manage-weight',
        },
      ],
    }
  },
  methods: {
    next() {
      const userGoals = this.goals.reduce(
        (res, { intercomEvent, selected }) => ({
          ...res,
          [intercomEvent]: selected,
        }),
        {}
      )

      this.$posthog.sendEvent(EVENTS.registration.skip)
      this.$intercom.update(userGoals)
      this.$analytics.track(events.register.goalsPage.nextClicked, userGoals)
      this.$store.dispatch('registration/setUserGoals', userGoals)
      this.$store.dispatch('registration/setRegistrationStepDone')
      this.$store.dispatch('registration/nextRegistrationStep')
    },
  },
  computed: mapGetters({
    userGoals: 'registration/userGoals',
    error: 'globals/noPlanError',
  }),
  mounted() {
    if (this.userGoals) {
      this.goals = this.goals.map((goal) => ({
        ...goal,
        selected: this.userGoals[goal.intercomEvent],
      }))
    }
    this.$posthog.sendEvent(EVENTS.registration.step1)
  },
}
</script>

<style lang="scss" scoped>
.goals-container {
  text-align: center;
  max-width: 710px;
  margin: 0 auto;
}

.goal-options {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.goal-option {
  flex-direction: column;
  margin: 0;
  & + .goal-option {
    margin: 12px 0 0;
  }

  ::v-deep .title {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
  }

  @include media-breakpoint-up(md) {
    max-height: 165px;
    max-width: 165px;
    padding: 2.5rem 1.6rem;
    ::v-deep .title {
      font-size: 1rem;
    }

    & + .goal-option {
      margin: 0 0 0 24px;
    }
  }
}

.select-box--selected {
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 41, 64, 0.2);
  border: solid 2px $clr-water;
}

.next-button {
  margin-top: 51px;
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
}
</style>
