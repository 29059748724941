<template>
  <div class="register-credentials-form mt-4 mx-md-auto mx-2">
    <div class="register-container">
      <h2>Let's set up your account</h2>
      <p class="subtitle mt-3 mt-md-4">
        Please provide your email, name and a password to set up your account
      </p>
      <MPAlert
        :text="registrationError"
        :is-dismissable="true"
        @dismiss="dismissError()"
      ></MPAlert>
      <form @submit.prevent="register" class="row mt-4 mt-md-5">
        <div class="top-column col-12 col-sm-12">
          <EmailInput
            v-model="email"
            :error="emailError"
            @blur="checkEmail()"
          />
          <FullNameInput
            class="mt-4"
            v-model="fullName"
            data-spec="full-name"
          />
          <PasswordInput class="mt-4" v-model="password" data-spec="password" />
          <div class="newsletter-input-group mt-4">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                v-model="generalEmailsOptin"
                class="custom-control-input email-checkbox"
                id="newsletter-input"
              />
              <label
                class="custom-control-label newsletter-label small text-muted"
                for="newsletter-input"
              >
                Get delicious recipes, expert health advice, culinary tips and
                special offers from Forks Over Knives and curated partners.
              </label>
            </div>
          </div>
          <MPButton
            class="mt-4 d-md-inline-block"
            type="submit"
            color="primary"
            size="full-width"
            data-spec="show-meal-plan"
            :disabled="!!emailError || !!registrationError || !!planError"
          >
            NEXT
          </MPButton>
          <h6 class="terms col-12 mt-3 mt-md-3">
            By signing up, I agree to the Forks Meal Planner
            <a
              target="_blank"
              href="https://support.forksmealplanner.com/article/FBsMvVt4fo-terms-of-service"
              >Terms of Service</a
            >
            and
            <a
              target="_blank"
              href="https://support.forksmealplanner.com/article/i4y3z8vu3q-privacy-policy"
              >Privacy Policy</a
            >
          </h6>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MPAlert, MPButton } from '@/components/common'
import EmailInput from '@/components/credentials/EmailInput'
import FullNameInput from '@/components/credentials/FullNameInput'
import PasswordInput from '@/components/credentials/PasswordInput'
import credentialsHandler from '@/handlers/CredentialsHandler'
import { events } from '@/utils/mixpanel.events'
import { ErrorMessage, MESSAGE, getMessageDetail } from '@/utils/ErrorMessage'
import { EVENTS } from '@/events/analytics'

export default {
  name: 'RegisterCredentialsForm',
  components: {
    MPAlert,
    MPButton,
    EmailInput,
    FullNameInput,
    PasswordInput,
  },
  computed: {
    ...mapGetters({
      adultServings: 'registration/adultServings',
      childServings: 'registration/childServings',
      soyIntolerance: 'registration/soyIntolerance',
      glutenIntolerance: 'registration/glutenIntolerance',
      nutsIntolerance: 'registration/nutsIntolerance',
      breakfastOptin: 'registration/breakfastOptin',
      snackOptin: 'registration/snackOptin',
      dessertOptin: 'registration/dessertOptin',
      additionalIntolerances: 'registration/additionalIntolerances',
      registrationError: 'registration/registrationError',
      planError: 'globals/noPlanError',
    }),
    email: {
      get() {
        return this.$store.state.registration.userData.email
      },
      set(value) {
        this.$store.dispatch('registration/setUserEmail', value)
      },
    },
    fullName: {
      get() {
        return this.$store.state.registration.userData.fullName
      },
      set(value) {
        this.$store.dispatch('registration/setUserFullName', value)
      },
    },
    password: {
      get() {
        return this.$store.state.registration.userData.password
      },
      set(value) {
        this.$store.dispatch('registration/setUserPassword', value)
      },
    },
    generalEmailsOptin: {
      get() {
        return this.$store.state.registration.userData.generalEmailsOptin
      },
      set(value) {
        this.$store.dispatch('registration/setUserGeneralEmailsOptin', value)
      },
    },
  },
  data() {
    return {
      enteredEmail: false,
      emailError: '',
    }
  },
  methods: {
    async checkEmail() {
      this.emailError = ''
      this.$store.dispatch('registration/setRegistrationError', '')
      this.$analytics.track(events.register.saveMealPlan.onlyMail.nextClicked)

      try {
        const request = await credentialsHandler.canCreateAccount(this.email)
        if (request.emailExists) {
          const message = getMessageDetail(
            'Account already exists',
            this.$store
          )
          ErrorMessage(message, this.$store)
          return
        }

        const emailIsValid = credentialsHandler.verifyEmail(this.email)
        if (!emailIsValid) {
          this.emailError = 'Are you sure this email is correct?'
          return
        }
      } catch (error) {
        if (error.detail) {
          const message = getMessageDetail(error.detail, this.$store)
          ErrorMessage(message, this.$store)
        } else {
          ErrorMessage(MESSAGE.ERROR.OOPS, this.$store)
        }
      }
    },
    register() {
      // if (registrationError) {
      //   return
      // }
      this.$emit('onRegister')
    },
    dismissError() {
      this.$store.dispatch('registration/setRegistrationError', '')
    },
  },
  mounted() {
    this.$posthog.sendEvent(EVENTS.registration.step5)
  },
}
</script>

<style lang="scss" scoped>
.register-container {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.top-column {
  @include media-breakpoint-up(md) {
    padding-right: 30px;
  }
}

.bottom-column {
  @include media-breakpoint-up(md) {
    margin-top: 43px;
    padding-left: 30px;
  }
}

.register-subtitle {
  color: $clr-navy-50;
}

.newsletter-input-group {
  padding-right: 0px;
}

.newsletter-label {
  padding-right: 0px;
  text-align: left;
  color: $clr-navy-70;
}

.terms {
  color: $clr-navy-70;
  line-height: 1.5;
}

.email-checkbox {
  width: 20px;
  height: 20px;
}

.social-buttons-wrapper {
  @include media-breakpoint-up(md) {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      left: -30px;
      top: 0;
      width: 1px;
      background-color: $clr-navy-15;
    }
    &:after {
      content: 'or';
      display: block;
      position: absolute;
      width: 20px;
      left: -40px;
      top: 50%;
      background-color: $clr-navy-3;
      color: $clr-navy-50;
      height: 50px;
      line-height: 50px;
      margin-top: -25px;
      text-align: center;
    }
  }
}

.next-button {
  border-radius: 4px;
  background-image: url('~@/assets/icons/arrow-right-register.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.button-container-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $white;
  padding: 15px;
  border-top: 1px solid #dddddd;
}

.subtitle {
  font-size: $h4-font-size;
}
</style>
