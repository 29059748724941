let env = {}
if (typeof window !== 'undefined') {
  env = window.envVariables
}

const PRODUCTION_ENVIRONMENT = {
  baseUrl: 'https://fok-meal-planning.herokuapp.com/api/',
  printBaseUrl: 'https://fok-meal-planning-printing.herokuapp.com/api/',
  fokLandingUrl: 'https://www.forksoverknives.com/meal-planner/',
  cookieDomain: 'forksmealplanner.com',
  stripeKey: 'pk_live_T7JFU8y9v4EnIBfqSX5xg12l',
  fomoUrlKey: '0BnRDQ7rI1lc0J2-ODxu1w',
  facebook: { appId: '1517844885176431', version: 'v13.0' },
  intercom: 'vy7tjqky',
  impactRadiusTrackerId: 15962,
  impactRadiusConversionId: 17020,
  GAKey: 'UA-12974007-2',
  GTagManager: 'GTM-NDXHDZD',
  stripeAppId: 'pk_live_T7JFU8y9v4EnIBfqSX5xg12l',
  fbqKey: '764574502379604',
  useSentry: true,
  mixpanelProxy: 'https://analytics.forksmealplanner.com',
  mixpanelKey: '861d80d48c4b8b97482c4e7f49024b6f',
  sentry: {
    projectId: '861a26f33aad4bbc9e932c391533a652',
    orgId: '1476256',
  },
  GTagKeys: {
    appId: 'AW-825469725',
    created: 'AW-825469725/x8hVCJKViHoQndbOiQM',
    monthly: 'AW-825469725/WsDFCJaxiHoQndbOiQM',
    quarterly: 'AW-825469725/h72dCLCViHoQndbOiQM',
    annual: 'AW-825469725/f8YeCMiV8XkQndbOiQM',
  },
  googleSignInClientID:
    '462852743813-55b49hgo994fo7ockkvc5upm3iav1bm9.apps.googleusercontent.com',
  firebaseConfig: {
    apiKey: 'AIzaSyA1CfV0xuBCSemm8Dp264ENh5wKv2dl6_o',
    authDomain: 'forks-meal-planner.firebaseapp.com',
    databaseURL: 'https://forks-meal-planner.firebaseio.com',
    projectId: 'forks-meal-planner',
    storageBucket: 'forks-meal-planner.appspot.com',
    messagingSenderId: '972972569460',
    appId: '1:972972569460:web:7f7e877d43a19a51de7221',
    measurementId: 'G-H19Y5JST9T',
  },
  postHogKey: 'phc_sWmjotNKMsjuLgaHysl7dtOHNFliqX9YAfWA6EDIAxn',
  chargebee: {
    site: 'forksoverknives',
  },
}

const STAGING_ENVIRONMENT = {
  baseUrl: 'https://fok-meal-planning-staging.herokuapp.com/api/',
  printBaseUrl: 'https://fok-meal-planning-staging.herokuapp.com/api/',
  fokLandingUrl: 'https://www.forksoverknives.com/meal-planner/',
  cookieDomain: 'staging.forksmealplanner.com',
  stripeKey: 'pk_test_HHlheHD12WrXnZJZ3ELcy2vc',
  fomoUrlKey: 'VdP_fIur8wKMepAt3Vhf6g',
  facebook: { appId: '155356255041598', version: 'v13.0' },
  intercom: 't78onlar',
  impactRadiusTrackerId: 15962,
  impactRadiusConversionId: 0,
  GAKey: 'UA-12974007-4',
  GTagManager: 'GTM-MS55SB5F',
  stripeAppId: 'pk_test_HHlheHD12WrXnZJZ3ELcy2vc',
  fbqKey: '',
  useSentry: false,
  mixpanelProxy: 'https://staging.analytics.forksmealplanner.com',
  mixpanelKey: 'e44fbcca87cd9e9fc7818cbd96706b04',
  sentry: {
    projectId: '8f24dec5ed1c4e53b2ee829109ace47f',
    orgId: '1476253',
  },
  GTagKeys: {},
  googleSignInClientID:
    '972972569460-gvdrgk5bk2pkum7sd9bt84pkh79io4to.apps.googleusercontent.com',
  firebaseConfig: {
    apiKey: 'AIzaSyA1CfV0xuBCSemm8Dp264ENh5wKv2dl6_o',
    authDomain: 'forks-meal-planner.firebaseapp.com',
    databaseURL: 'https://forks-meal-planner.firebaseio.com',
    projectId: 'forks-meal-planner',
    storageBucket: 'forks-meal-planner.appspot.com',
    messagingSenderId: '972972569460',
    appId: '1:972972569460:web:7f7e877d43a19a51de7221',
    measurementId: 'G-H19Y5JST9T',
  },
  postHogKey: 'phc_BLhxrYh8boWCjAxeY4Ni76AHATjAhaHCdANijpYabmp',
  chargebee: {
    site: 'forksoverknives-test',
  },
}

const QA_ENVIRONMENT = {
  ...STAGING_ENVIRONMENT,
  baseUrl: `${env.backendUrl}/api/`,
  printBaseUrl: `${env.printBackendUrl}/api/`,
  cookieDomain: 'fok-meal-planning-qa-frontend.herokuapp.com',
  useSentry: true,
  mixpanelKey: '71618c28ef8a518a9d6bd5b3de4e41a9',
  sentry: {
    projectId: 'fb22702bdaf444b59766f3fdc444d023',
    orgId: '5249512',
  },
  facebook: { appId: '155356255041598', version: 'v4.0' },
}

const DEVELOPMENT_ENVIRONMENT = {
  ...QA_ENVIRONMENT,
  cookieDomain: 'localhost',
  useSentry: false,
}

export {
  STAGING_ENVIRONMENT,
  PRODUCTION_ENVIRONMENT,
  QA_ENVIRONMENT,
  DEVELOPMENT_ENVIRONMENT,
}
